import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";

export function Preise() {
    const colLeftProps = {
        xs: 24,
        // sm: 16,
        md: 16,
        // lg: 8,
        // xl: 10
    }
    
    const colRightProps = {
        xs: 24,
        // sm: 8,
        md: 8,
        // lg: 8,
        // xl: 4
        style: {paddingBottom: '20px'}
    }
    
    const rowProps = {
        // gutter: [20, 40]
    }

    return (
        <>
            <div style={{ backgroundColor: '#9E5288', color: '#fff' }}>
                <p style={{ fontSize: '2em', marginBottom: 0, padding: '10px', paddingLeft: '30px' }}>Preise</p>
            </div>
            <div>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Trageberatung"
                    extra={<Link data-weight="5" to="/angebote/trageberatung"><div>Weitere Infos</div></Link>}
                >
                    <Row {...rowProps}>
                        <Col {...colLeftProps}>
                            Basisberatung<br />
                            <small>(beinhaltet das Testen einer Tragehilfe / Tuch für 7 Tage)</small>
                        </Col>
                        <Col {...colRightProps}>
                            40,00 Euro
                        </Col>
                    </Row>
                    <Row>
                        <Col {...colLeftProps}>
                            Folgeberatung
                        </Col>
                        <Col {...colRightProps}>
                            10,00 Euro/je angefangene 15 Min.
                        </Col>
                    </Row>
                    <div style={{ paddingTop: '15px' }}>
                        Fahrtkosten ab 6 km 0,50 Euro/km
                    </div>
                </Card>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Outdoor-Fit mit Kind"
                    extra={<Link data-weight="5" to="/angebote/outdoorfit"><div>Weitere Infos</div></Link>}
                >
                    <Row>
                        <Col {...colLeftProps}>
                            6-Wochenkurs
                        </Col>
                        <Col {...colRightProps}>
                            59,00 Euro
                        </Col>
                    </Row>
                    <Row>
                        <Col {...colLeftProps}>
                            8-Wochenkurs
                        </Col>
                        <Col {...colRightProps}>
                            79,00 Euro
                        </Col>
                    </Row>
                </Card>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Stillberatung"
                    extra={<Link data-weight="5" to="/angebote/stillberatung"><div>Weitere Infos</div></Link>}
                >
                    <Row>
                        <Col {...colLeftProps}>
                            Kennenlerngespräch
                        </Col>
                        <Col {...colRightProps}>
                            kostenfrei <small>(telefonisch bis zu 15 Min.)</small>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 16 }}>
                        <Col {...colLeftProps}>
                            Stillvorbereitung
                        </Col>
                        <Col {...colRightProps}>
                            49,00 Euro <small>(Einzeltermin)</small><br />
                            69,00 Euro <small>(mit einem zweiten Paar)</small><br />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 16 }}>
                        <Col {...colLeftProps}>
                            Erstgespräch
                        </Col>
                        <Col {...colRightProps}>
                            49,00 Euro <small>(persönlich, per Telefon / Video)</small>
                        </Col>
                    </Row>
                    <Row>
                        <Col {...colLeftProps}>
                            Notfalltermin
                        </Col>
                        <Col {...colRightProps}>
                            + 20,00 Euro <small>(innerhalb von 24 Std.)</small>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 16 }}>
                        <Col {...colLeftProps}>
                            Folgegespräch
                        </Col>
                        <Col {...colRightProps}>
                            12,50 Euro/je angefangene 15 Min.
                        </Col>
                    </Row>
                    <div style={{ paddingTop: '15px' }}>
                        Fahrtkosten ab 6 km 0,50 Euro/km
                    </div>
                </Card>
                <div style={{padding: '50px', textAlign: 'center', fontSize: 'small'}}>Stand: November 2021 | Änderungen und Irrtümer vorbehalten</div>
            </div>
        </>

    );
}

import { useState, useEffect } from 'react';
import { Button, Drawer, Layout, Menu } from 'antd';
import moment from 'moment';
import './App.css';
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  Home,
  Angebote,
  Admin,
  Bilder,
  Preise,
  Termine,
  Kontakt,
  Impressum,
  Datenschutz
} from './pages'
import { MenuOutlined, PhoneOutlined } from '@ant-design/icons';
import GA4React, { useGA4React } from "ga-4-react";

const ga4react = new GA4React("G-BHRZ4XTR2Q");

const { Content, Footer } = Layout;

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [sidebar, setSidebar] = useState(false);
  const [activeMenu, setActiveMenu] = useState('menuHome');

  const ga = useGA4React();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  let navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(pathname.split('/')[1])
    switch (pathname.split('/')[1]) {
      case 'angebote':
        setActiveMenu("menuAngebote")
        break;
      case 'termine':
        setActiveMenu("menuTermine")
        break;
      case 'kontakt':
        setActiveMenu("menuKontakt")
        break;
        case 'preise':
          setActiveMenu("menuPreise")
          break;
        case 'impressum':
        setActiveMenu("none")
        break;
      default:
        setActiveMenu("menuHome")
        break;
    }
  }, [pathname]);

  const NavBar = (props) => (
    <Menu
      className="menu"
      theme="dark"
      mode={props.mode || "horizontal"}
      selectedKeys={[activeMenu]}
      onSelect={(x) => {
        navigate(x.item.props.path);
        if (sidebar) setSidebar(false);
      }}
    >
      <Menu.Item key="menuHome" path="/">Home</Menu.Item>
      {/* <Menu.Item key="menuAbout" path="/about">Über mich</Menu.Item> */}
      <Menu.SubMenu key="menuAngebote" title="Angebote">
        <Menu.Item key="menuAngebote1" path="/angebote/stillberatung"><div>Stillberatung</div></Menu.Item>
        <Menu.Item key="menuAngebote2" path="/angebote/stillvorbereitung"><div>Stillvorbereitung</div></Menu.Item>
        <Menu.Item key="menuAngebote3" path="/angebote/outdoorfit"><div>Outdoor-Fit mit Kind</div></Menu.Item>
        <Menu.Item key="menuAngebote4" path="/angebote/trageberatung"><div>Trageberatung</div></Menu.Item>
        <Menu.Item key="menuAngebote5" path="/angebote/elterncafe"><div>Elterncafé</div></Menu.Item>
        <Menu.Item key="menuAngebote6" path="/angebote/babymassage"><div>Babymassage</div></Menu.Item>
        <Menu.Item key="menuAngebote7" path="/angebote/stillgruppe"><div>Stillgruppe</div></Menu.Item>
        <Menu.Item key="menuAngebote8" path="/angebote/waldworkshop"><div>Wald-Workshops</div></Menu.Item>
      </Menu.SubMenu>
      {/* <Menu.Item key="menuBilder" path="/bilder">Bilder</Menu.Item> */}
      <Menu.Item key="menuTermine" path="/termine">Termine</Menu.Item>
      <Menu.Item key="menuPreise" path="/preise">Preise</Menu.Item>
      <Menu.Item key="menuKontakt" path="/kontakt">Kontakt</Menu.Item>
      {localStorage.getItem('admin') && <Menu.Item style={{color: 'red', fontWeight: 'bold'}} key="menuAdmin" path="/" onClick={() => localStorage.removeItem('admin') }><div>Logout</div></Menu.Item>}
    </Menu>
  )


  return (
    <Layout className="layout" style={{ minHeight: "100vh" }}>
      <header className="header" style={isMobile ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white' } : null}>
        {isMobile && <Button type="primary" style={{ padding: '0 10px', marginLeft: '20px', marginRight: '60px' }} onClick={() => setSidebar(!sidebar)}><MenuOutlined /></Button>}
        <img alt="Logo" src="/logo_nav.png" className="logo" style={isMobile ? { textAlign: 'center' } : { float: 'left' }} />
        {isMobile && <Link to="/kontakt"><Button type="primary" style={{ padding: '0 10px', margin: '0 20px' }}><PhoneOutlined /> Kontakt</Button></Link>}
        {!isMobile && <NavBar />}
      </header>
      <Drawer
        title="Neues Bauchgefühl"
        placement="left"
        closable={true}
        onClose={() => setSidebar(false)}
        visible={sidebar}
        bodyStyle={{ padding: 0 }}
      >
        <img alt="Logo" src="/logo.png" style={{ width: '100%' }} />
        <div style={{ margin: '10px' }}>
          <NavBar mode="inline" />
        </div>
      </Drawer>
      <Content style={{ marginTop: '64px', padding: '0 0px' }}>
        {/* <Breadcrumbs crumbs={[
          {
            name: "Home",
            path: "/"
          },
          {
            name: "Bilder",
            path: "/bilder"
          }
        ]} /> */}
        <div className="main-content">
          <Routes>
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/angebote/:angebot" element={<Angebote isMobile={isMobile} />} />
            {/* <Route path="/bilder" element={<Bilder />} /> */}
            <Route path="/admin" element={<Admin />} />
            <Route path="/termine" element={<Termine />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/preise" element={<Preise />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <div>© 2021-{moment().format("YYYY")} by Neues Bauchgefühl - Janina Schnaubelt</div>
        <div><Link to="/impressum">Impressum</Link> - <Link to="/datenschutz">Datenschutz</Link> - <Link to="/admin" style={{color: 'grey'}}>Admin</Link></div>
      </Footer>
    </Layout>
  );
}

(async () => {
  await ga4react.initialize();


})();

export default App;
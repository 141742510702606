import { Button, Carousel } from 'antd';
import { About, Kontakt } from '.';
import FollowAt from "react-social-media-follow";
import {
  Link
} from "react-router-dom";
const h3style = {
  height: '160px',
  // color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  // background: '#364d79',
};

export function Home() {
  return (
    <>
      <Carousel
        effect="fade"
        autoplay
        autoplaySpeed={5000}
      >
        <div>
          <div className="slide" style={{ backgroundImage: "url('/baby-21167.jpg')" }}>
            {/* <h3 style={h3style}>Herzlich Willkommen!</h3> */}
            <img src="/logo.png" className="slide-1-logo"/>
          </div>
        </div>
        {/* <div>
          <div className="slide" style={{ backgroundImage: "url('/baby-4100420.jpg')" }}>
            <h3 style={h3style}>1</h3>
          </div>
        </div>
        <div>
          <div className="slide" style={{ backgroundImage: "url('/iStock-614211300.jpg')" }}>
            <h3 style={h3style}>2</h3>
          </div>
        </div>
        <div>
          <div className="slide" style={{ backgroundImage: "url('/iStock-500785660.jpg')" }}>
            <h3 style={h3style}>3</h3>

          </div>
        </div> */}

        {/* <div>
      <h3 style={contentStyle}>2</h3>
    </div>
    <div>
      <h3 style={contentStyle}>3</h3>
    </div>
    <div>
      <h3 style={contentStyle}>4</h3>
    </div> */}
      </Carousel>

      <div className="text-white w-100 text-center" style={{padding: '40px', backgroundImage: "url('/logo-background.png')", backgroundColor: '#782A5E', backgroundSize: 'contain'}}>
      <div style={{fontSize: '1.7em', fontStyle: 'italic', fontFamily: 'Yantramanav'}}>„Kinder können nicht verwöhnt werden, indem sie "zu viel" von dem bekommen, was sie wirklich brauchen.“</div>
      <div style={{fontSize: '1.3em', fontFamily: 'Yantramanav Light'}}>- Jesper Juul -</div>
      </div>

      <About />

      <div className="bg-color-dark text-white w-100 text-center" style={{padding: '30px'}}>
      <div style={{fontSize: '3em', fontFamily: 'Yantramanav'}}>Angebote & Kurse</div>
      <div style={{fontSize: '16pt', fontFamily: 'Yantramanav Light', color: '#fff', marginTop: '20px'}}>
      Weitere Informationen zu meinen Angeboten sowie die nächsten Termine finden Sie hier:</div>
        <div className="angebote-container">
        <ul className="cloud">
          <li><Link data-weight="5" to="/angebote/stillberatung"><div>Stillberatung</div></Link></li>
          <li><Link data-weight="4" to="/angebote/stillvorbereitung"><div>Stillvorbereitung</div></Link></li>
          <li><Link data-weight="7" to="/angebote/outdoorfit"><div>Outdoor-Fit mit Kind</div></Link></li>
          <li><Link data-weight="6" to="/angebote/trageberatung"><div>Trageberatung</div></Link></li>
          <li><Link data-weight="4" to="/angebote/elterncafe"><div>Elterncafé</div></Link></li>
          <li><Link data-weight="8" to="/angebote/babymassage"><div>Babymassage</div></Link></li>
          <li><Link data-weight="3" to="/angebote/stillgruppe"><div>Stillgruppe</div></Link></li>
          <li><Link data-weight="5" to="/angebote/waldworkshop"><div>Wald-Workshops</div></Link></li>
        </ul>
        </div>
        
      <div style={{fontSize: '1.3em', fontFamily: 'Yantramanav', marginTop: '20px'}}>
      <Link to="/termine"><Button size="large" type="dashed">Zu den nächsten Terminen</Button></Link>
      </div>
      </div>

      <div className="w-100 text-center" style={{color: '#5F3159', padding: '40px', backgroundColor: '#fff'}}>
      <div style={{fontSize: '2em',fontFamily: 'Yantramanav'}}>Lust auf noch mehr Eindrücke und aktuelle Informationen zu den Angeboten?</div>
      <div style={{fontSize: '1.5em',fontFamily: 'Yantramanav'}}>Dann folgt uns auf</div>
      <FollowAt 
      color="#5F3159"
      hoverColor="#9E5288"
      iconSize={3}
      links = {[
      'https://www.facebook.com/NeuesBauchgef%C3%BChl-Oberhausen-100110542210335',
      'https://www.instagram.com/neues_bauchgefuehl',
    ]}/>
      </div>

      <Kontakt />

    </>
  );
}
